import { Route, Routes, Navigate } from 'react-router-dom';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useAppDispatch } from '../hooks/useTypedSelector';
import { useMsal } from '@azure/msal-react';
import { getAllForms } from '../slices/form/form-assessment-slice';
import { FormAssessmentResponse } from '../models/forms/form-assessment-response.model';
import LoadingSpinner from '../components/loader/loader';
import PrivacyNotice from '../pages/privacy-notice/privacy-notice';
import sessionService from '../services/session.service';
import ErrorPage from '../pages/error/error-page';
import UserProfile from '../pages/admin/user-profile.tsx/user-profile';
import { getTenantConfigurationById, setStylesheet } from '../slices/tenants/tenant-slice';
import { useSelector } from 'react-redux';
import RootPage from '../pages/root/root-page';
import FormManagement from '../pages/admin/form-management';
import FormEditor from '../pages/admin/form-editor';
import CustomAssessment from '../pages/assessments/custom/custom-assessment';
import { fetchRoles } from '../slices/sessions/session-slice';
import RoleManagement from '../pages/admin/role-management';
import RoleEditor from '../pages/admin/role-editor';
import CookiePolicy from '../pages/privacy-notice/cookie-policy';

const Dashboard = lazy(() => import('../pages/dashboard/dashboard'));
const SubjectlessDashboard = lazy(() => import('../pages/subjectless-dashboard/subjectless-dashboard'));
const NeedAssesment = lazy(() => import('../pages/assessments/self/assessment'));
const LandingPage = lazy(() => import('../pages/welcome/landing-page'));
const SubjectlessForm = lazy(() => import('../pages/subjectless/subjectless'));
const Signup = lazy(() => import('../pages/register/signup'));
const FinancialAssessment = lazy(
  () => import('../pages/assessments/financial/financial-assessment'),
);
const UserRegistrationAssessment = lazy(
  () => import('../pages/assessments/user-registration/user-registration-assessment'),
);
const ChangeRequest = lazy(() => import('../pages/assessments/change/change-request'));
const ChangeRequestMenu = lazy(() => import('../pages/assessments/change/change-request-menu'));
const EmailConfirmation = lazy(() => import('../pages/email-confirmation/email-confirmation'));
const MSALSignInPage = lazy(() => import('../pages/msal-signin/msal-signin'));
const AccountConfirmation = lazy(() => import('../pages/register/confirmation/confirmation'));
const UserManagement = lazy(() => import('../pages/admin/user-management'));
const CareInformation = lazy(() => import('../pages/care-information/care-information'));

const RoutesNav = () => {
  const dispatch = useAppDispatch();
  const { instance } = useMsal();
  const isUserSignedIn = instance.getAllAccounts().length > 0;
  const tenantSlice = useSelector((state: any) => state.tenants);
  const [formassessmentResponse, setFormassessmentResponse] = useState<
    FormAssessmentResponse[] | undefined
  >(undefined);

  const ids = [
    'e5cbd1e5-d39c-4705-9b15-4785e9316bf7', // Financial Assessment
    'a287d371-217a-4181-ba05-0a7e93bf2ec8', // Self Assessment
    '3b25e4bb-e1b9-43d8-843b-7dbdddfda382', // User Registration
    'db832930-1edf-4a12-a971-9aa9b98ef127', // Subjectless
  ];

  function getCurrentTenantName(): string {
    if (sessionService.session != null) return sessionService.session.tenant;
    const loc = window.location.pathname.split('/');
    return loc[1];
  }

  useEffect(() => {
    if (!isUserSignedIn) {
      return;
    }
    dispatch(getAllForms())
      .unwrap()
      .then((forms) => {
        let accumulatedForms: FormAssessmentResponse[] = [];
        if (forms.length > 0) {
          ids.forEach((id) => {
            accumulatedForms = [...accumulatedForms, ...forms.filter((form) => form.schemaId === id)];
          });
          setFormassessmentResponse(accumulatedForms); 
        }
      })
      .catch((err) => {
        console.error('Err', err);
      });
  }, []);

  useEffect(() => {
    if (sessionService.session) {
      dispatch(fetchRoles()).unwrap().catch((error:Error) => {
        console.error('Failed to fetch roles:', error);
      });
    }
  }, [dispatch]);

  useEffect(() => {
    const time = sessionStorage.getItem('configTimestamp') || '0';
    const config = sessionStorage.getItem('config');
    const cacheIsExpired =
      !time || new Date().getTime() - parseInt(time) > tenantSlice.entitites?.cacheDuration * 1000;
    const pathIsDifferent = window.location.pathname.split('/')[1] !== tenantSlice.entities?.name;
    let element: HTMLStyleElement;

    const fetchData = () => {
      dispatch(getTenantConfigurationById())
        .unwrap()
        .then((obj) => {
          if (obj.tenantCss.blobName) {
            setStylesheet(obj).then((res) => {
              element = res;
            });
          }
        })
        .catch((err) => {
          console.error('Error', err);
        });
    };

    if (!tenantSlice.entities || cacheIsExpired || pathIsDifferent || !config) {
      fetchData();
    }



    return () => {
      if (element && document.head.contains(element)) {
        document.head.removeChild(element);
      }
    };
  }, [dispatch, tenantSlice.entities?.name]);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route path="/:tenantName/email-confirmation" element={<EmailConfirmation />} />
        <Route path="/:tenantName/signup" element={<Signup />} />
        <Route path="/:tenantName/dashboard" element={<Dashboard />} />
        <Route path="/:tenantName/subjectless-dashboard" element={<SubjectlessDashboard />} />
        <Route
          path="/:tenantName/userregistrationassessment"
          element={
            <UserRegistrationAssessment
              assessment={
                formassessmentResponse! &&
                formassessmentResponse.find((x) => x.schemaId === ids[2])!
              }
            />
          }
        />
        <Route
          path="/:tenantName/assessment"
          element={
            <NeedAssesment
              assessment={
                formassessmentResponse! &&
                formassessmentResponse.find((x) => x.schemaId === ids[1])!
              }
            />
          }
        />
        <Route
          path="/:tenantName/financialassessment"
          element={
            <FinancialAssessment
              assessment={
                formassessmentResponse! &&
                formassessmentResponse.find((x) => x.schemaId === ids[0])!
              }
            />
          }
        />
        <Route
          path="/:tenantName/changerequest"
          element={
            <ChangeRequest
              assessment={
                formassessmentResponse! &&
                formassessmentResponse.find((x) => x.schemaId === ids[10])!
              }
            />
          }
        />
        <Route
          path="/:tenantName/customform/:customId"
          element={
            <CustomAssessment
              formassessmentResponse={formassessmentResponse}
            />
          }
        />
        {!isUserSignedIn && <Route
          path="/:tenantName/subjectless"
          element={
            <SubjectlessForm
              assessment={formassessmentResponse! &&
                formassessmentResponse.find((x) => x.schemaId === ids[3])!}
            />
          }
        />}
        <Route path="/:tenantName/changerequestmenu" element={<ChangeRequestMenu />} />
        <Route path="/:tenantName/confirmation" element={<AccountConfirmation />} />
        <Route path="/:tenantName/welcome" element={<LandingPage />} />
        <Route path="/:tenantName/error" element={<ErrorPage />} />
        <Route path="/msal-signin" element={<MSALSignInPage />} />
        <Route path="/" element={<RootPage />} />
        <Route path="/*" element={<Navigate to={`/${getCurrentTenantName()}/welcome`} />} />
        <Route path="/:tenantName/user-management" element={<UserManagement pageNumber={1} />} />
        <Route path="/:tenantName/form-management" element={<FormManagement pageNumber={1} />} />
        <Route path="/:tenantName/form-management/form-editor/:schemaId" element={<FormEditor />} />
        <Route path="/:tenantName/user-management/:userId" element={<UserProfile />} />
        <Route path="/:tenantName/user-profile" element={<UserProfile />} />
        <Route path="/:tenantName/role-management" element={<RoleManagement pageNumber={1}/>} />
        <Route path="/:tenantName/role-management/:roleId" element={<RoleEditor/>} />
        <Route path="/:tenantName/role-management/new" element={<RoleEditor/>} />
        <Route path="/:tenantName/privacy-and-cookies-policy" element={<PrivacyNotice />} />
        <Route path="/:tenantName/care-information" element={<CareInformation />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesNav;
