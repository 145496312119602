import './footer.scss';
import LogoImg from '../../assets/images/footer-logo.svg';
import { TenantModel } from '../../models/tenants/tenant-model';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import { Role } from '../../Enums/Role';
import sessionService from '../../services/session.service';
import { EndSessionRequest } from '@azure/msal-browser/dist/request/EndSessionRequest';
import { FloatButton } from 'antd';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';

export type Props = {
  tenant: TenantModel;
};

function Footer({ tenant }: Props) {
  const { accounts } = useMsal();
  const { instance } = useMsal();

  const isAuthenticated = useIsAuthenticated();
  const tenantName = tenant?.name;
  const currentAccount = instance.getAccountByHomeId(accounts[0]?.homeAccountId);
  const currentSession = sessionService.session?.session || null;
  const isAdmin = useSelector((state: RootState) => state.sessions.isAdmin);

  const logout = () => {
    try {
      sessionService
        .signOut()
        .then((_) => {
          const endSessionRequest: EndSessionRequest = {
            account: currentAccount,
            postLogoutRedirectUri: `/${tenantName}/welcome`,
          };
          instance.logoutRedirect(endSessionRequest);
        })
        .then(() => {
          sessionStorage.removeItem('accp:session');
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <footer className="govuk-footer" role="contentinfo">
      <div className="govuk-width-container">
        <div className="govuk-footer__meta">
          <div className="govuk-footer__meta-item govuk-footer__meta-item--grow">
            <h2 className="govuk-visually-hidden">Support links</h2>
            {isAuthenticated && (
              <ul className="govuk-footer__inline-list">
                <li className="govuk-footer__inline-list-item">
                  <a className="govuk-footer__link" href={`/${tenantName}/dashboard`}>
                    Home
                  </a>
                </li>
                {isAdmin && !currentSession?.citizenSession?.citizenId && (
                  <>
                    <li className="govuk-footer__inline-list-item">
                      <a className="govuk-footer__link" href={`/${tenantName}/user-management`}>
                        User Management
                      </a>
                    </li>
                    <li className="govuk-footer__inline-list-item">
                      <a className="govuk-footer__link" href={`/${tenantName}/form-management`}>
                        Form Management
                      </a>
                    </li>
                  </>
                )}
                <li className="govuk-footer__inline-list-item">
                  <a className="govuk-footer__link" onClick={logout}>
                    Sign Out
                  </a>
                </li>
              </ul>
            )}
            <FloatButton.BackTop
              visibilityHeight={isAuthenticated ? 400 : 100}
              tooltip={<div>Back to top</div>}
            />
            <br />
            <span className="govuk-footer__licence-description">
              <Link
                className="govuk-footer__link"
                to={`/${tenantName}/privacy-and-cookies-policy`}
                target="_blank"
                rel="license noreferrer"
              >
                Information regarding Privacy and Cookies policies
              </Link>
            </span>
          </div>
          <div className="govuk-footer__meta-item access-logo">
            {isAuthenticated ? (
              <Link to={`/${tenantName}/dashboard`}>
                <img src={LogoImg} alt="Access Community Gateway" />
              </Link>
            ) : (
              <Link to={`/${tenantName}/signup`}>
                <img src={LogoImg} alt="Access Community Gateway" />
              </Link>
            )}
          </div>
        </div>
        <div className="incorporated-blurb">
          Access UK Limited is a company incorporated in England and Wales, (no. 2343760) and whose
          registered address is Access UK Ltd., The Old School, Stratford St. Mary, Colchester,
          Essex, CO7 6LZ, United Kingdom. VAT no. GB 108221356
        </div>
        <div className="copyright-blurb">
          Copyright © 2023 - 2024 Access UK Ltd
        </div>
      </div>
    </footer>
  );
}

export default Footer;
