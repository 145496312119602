import { useEffect, useRef, useState } from 'react';
import { InputElementProps } from '../input-interface';
import { errorMessage, classNameError } from '../../../../helpers/helper-errors';

import './datefield.scss';
import { FieldValues, UseControllerReturn, useController } from 'react-hook-form';

const DateField = ({
  labelHeading,
  name,
  formValue,
  register,
  errors,
  helper,
  validationSchema,
  required,
  className,
  type,
  setError,
  clearErrors,
  control,
  ...props
}: InputElementProps) => {
  let datefieldControllers: UseControllerReturn<FieldValues, string>[];
  const names = [`${name}Day`, `${name}Month`, `${name}Year`];
  const [requiredField, setRequiredField] = useState(required);
  
  if (control) {
    const controllers = names.map((name) => {
      return useController({
        name: name,
        control: control,
        rules: { required: requiredField },
      });
    });
    datefieldControllers = controllers;
  }

  const [dateChange, setDateChange] = useState(0);

  const [dayValue, setDayValue] = useState(
    formValue?.entityValues?.filter((i) => i.id === `${name}Day`)[0]?.value,
  );
  const [monthValue, setMonthValue] = useState(
    formValue?.entityValues?.filter((i) => i.id === `${name}Month`)[0]?.value,
  );
  const [yearValue, setYearValue] = useState(
    formValue?.entityValues?.filter((i) => i.id === `${name}Year`)[0]?.value,
  );

  const year = Number(yearValue);
  const month = Number(monthValue) - 1;
  const day = Number(dayValue);

  const changeDateBool = () => {
    setDateChange((prev) => prev + 1);
  };

  const isValidDate = () => {
    if (!dayValue || !monthValue || !yearValue) return true;

    const dateToValidate = new Date(year, month, day);

    if (
      dateToValidate.getFullYear() !== year ||
      dateToValidate.getMonth() !== month ||
      dateToValidate.getDate() !== day ||
      year < 1000
    ) {
      setDateChange(0);
      return false;
    } else {
      clearErrors?.(`${name}Day`);
      clearErrors?.(`${name}Month`);
      clearErrors?.(`${name}Year`);
      if (dateChange < 2) changeDateBool();
      return true;
    }
  };

  useEffect(() => {
    isValidDate();
    if (!required) {
      setRequiredField(Boolean(dayValue || monthValue || yearValue));
    }
  }, [day, month, year, dateChange]);

  return (
    <fieldset className="govuk-fieldset" role="group" key={`fieldset-${name}`}>
      <legend className="govuk-label govuk-label--s">{labelHeading}</legend>

      {helper && (
        <div id={`${name}-hint`} className="govuk-hint">
          {helper}
        </div>
      )}

      <span
        data-testid={`${name}-error-msg`}
        className="govuk-error-message error__message-group"
      >
        {errorMessage(name!, `Enter a complete ${labelHeading}`, 'required', requiredField!, errors, 'Day') ||
          errorMessage(name!, `Enter a complete ${labelHeading}`, 'required', requiredField!, errors, 'Month') ||
          errorMessage(name!, `Enter a complete ${labelHeading}`, 'required', requiredField!, errors, 'Year')}

        {errorMessage(name!, `${errors[`${name}Day`]?.message}`, 'pattern', requiredField!, errors, 'Day') ||
          errorMessage(name!, `${errors[`${name}Month`]?.message}`, 'pattern', requiredField!, errors, 'Month') ||
          errorMessage(name!, `${errors[`${name}Year`]?.message}`, 'pattern', requiredField!, errors, 'Year')}

        {errorMessage(name!, 'Date is invalid', 'isValidDate', requiredField!, errors, 'Year')}
      </span>

      <div className="govuk-date-input" id={`${name}-group`} role="datefieldgroup">
        <div className="govuk-date-input__item">
          <div className="govuk-form-group">
            <label className={`${className} govuk-label`} htmlFor={`${name}Day`}>
              Day
            </label>
            <input
              {...register(
                `${name}Day`,
                requiredField
                  ? {
                      required: true,
                      pattern: {
                        value: /\b(0[1-9]|[12][0-9]|3[01])\b/,
                        message: `${labelHeading} must be in the right format`,
                      },
                      validate: { isValidDate },
                    }
                  : {
                      required: false,
                      validate: { isValidDate },
                    },
              )}
              id={`${name}Day`}
              maxLength={2}
              aria-errormessage={`${name}Year-err`}
              aria-required="true"
              aria-invalid={errors[`${name}Day`] ? 'true' : 'false'}
              type={type}
              name={`${name}Day`}
              value={dayValue}
              onChange={(e) => {
                changeDateBool();
                setDayValue(e.currentTarget.value);
                if (datefieldControllers) {
                  datefieldControllers[0]?.field.onChange(e.currentTarget.value);
                }
              }}
              placeholder="DD"
              className={`govuk-input govuk-date-input__input govuk-input--width-2 
              ${classNameError(name!, requiredField!, errors, 'Day')}`}
              {...props}
            />
          </div>
        </div>

        <div className="govuk-date-input__item">
          <div className="govuk-form-group">
            <label className={`${className} govuk-label`} htmlFor={`${name}Month`}>
              Month
            </label>
            <input
              {...register(
                `${name}Month`,
                requiredField
                  ? {
                      required: true,
                      pattern: {
                        value: /\b(0[1-9]|0[01][0-9]|1[1-2]|1[02])\b/,
                        message: `${labelHeading} must be in the right format`,
                      },
                      validate: { isValidDate },
                    }
                  : {
                      required: false,
                      validate: { isValidDate },
                    },
              )}
              id={`${name}Month`}
              maxLength={2}
              aria-errormessage="date-err"
              aria-required="true"
              aria-invalid={errors[`${name}Month`] ? 'true' : 'false'}
              type={type}
              name={`${name}Month`}
              value={monthValue}
              onChange={(e) => {
                setMonthValue(e.currentTarget.value);
                if (datefieldControllers) {
                  datefieldControllers[1].field.onChange(e.currentTarget.value);
                }
              }}
              placeholder="MM"
              className={`govuk-input govuk-date-input__input govuk-input--width-2 
              ${classNameError(name!, requiredField!, errors, 'Month')}`}
              {...props}
            />
          </div>
        </div>

        <div className="govuk-date-input__item">
          <div className="govuk-form-group">
            <label className={`${className} govuk-label`} htmlFor={`${name}Year`}>
              Year
            </label>
            <input
              {...register(
                `${name}Year`,
                requiredField
                  ? {
                      required: true,
                      pattern: {
                        value: /^[0-9]{4}$/,
                        message: `${labelHeading} must be in the right format`,
                      },
                      validate: { isValidDate },
                    }
                  : {
                      required: false,
                      validate: { isValidDate },
                    },
              )}
              id={`${name}Year`}
              maxLength={4}
              aria-errormessage="date-err"
              aria-required="true"
              aria-invalid={errors[`${name}Year`] ? 'true' : 'false'}
              type={type}
              name={`${name}Year`}
              value={yearValue}
              onChange={(e) => {
                setYearValue(e.currentTarget.value);
                if (datefieldControllers) {
                  datefieldControllers[2].field.onChange(e.currentTarget.value);
                }
              }}
              placeholder="YYYY"
              className={`govuk-input govuk-date-input__input govuk-input--width-3 
              ${classNameError(name!, requiredField!, errors, 'Year')}`}
              {...props}
            />
          </div>
        </div>
      </div>
    </fieldset>
  );
};

export default DateField;
