import { Suspense, lazy } from 'react';
import LoadingSpinner from '../../components/loader/loader';
import { useSelector } from 'react-redux';
import './privacy-notice.scss';
import { ProductName } from '../../constants/product-info-constants';
import DOMPurify from 'dompurify';

const DashboardLayout = lazy(() => import('../../components/layout/dashboard/dashboard-layout'));

function PrivacyNotice() {
  const tenant = useSelector((state: any) => state.tenants.entities);
  
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <DashboardLayout>
      <div id="privacyNotice" className="privacy-notice-container govuk-width-container">
          <main className="govuk-main-wrapper main__container" id="main-content" role="main">
            <div className="govuk-grid-row privacy-background">
            {tenant.customPolicy && (
                  <>
                    <div
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tenant.customPolicy) }}
                    />
                    <br />
                    <br />
                    <hr />
                  </>
                )}
              <h1>The Access Group - Privacy and Cookies Policy</h1>
              <ul>
                <li className='external-links list-items'>
                <a
                      href="https://www.theaccessgroup.com/en-gb/privacy-notice-for-access-products/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Notice for Access Products | The Access Group
                    </a>
                </li>
                <li className='external-links list-items'>
                <a
                      href="/cookie-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Cookie Policy for Access Products | The Access Group
                    </a>
                </li>
              </ul>
            </div>
            </main>
          </div>
      </DashboardLayout>
    </Suspense>
  );
}

export default PrivacyNotice;
